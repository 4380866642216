import { Routes, Route } from 'react-router-dom';
import App from './App';
import Thanks from './Thanks';

const Router = () => {
  return (
    <Routes>
      <Route path="/thanks" element={<Thanks />} />
      <Route path="/" element={<App />} />
    </Routes>
  );
};

export default Router;
