import { Star } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import styles from './App.module.scss';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { ScaleLoader } from 'react-spinners';
import Swal from 'sweetalert2';

const textData = {
  title: {
    nl: 'Hoe zou u onze service beoordelen?',
    en: 'How would you rate our service?',
    fr: 'Comment évaluez-vous notre transport ?',
  },
  intro: {
    nl: (
      <>
        Beste klant, we streven bij Gorselé naar een 5-sterren service. <br />
        Om onze diensten steeds te kunnen verbeteren willen we u vragen wat u
        van onze service vond.
      </>
    ),
    en: (
      <>
        Dear customer, at Gorsele we strive for a 5-star service. <br />
        In order to continuously improve our services, we would like to ask you
        what you think of your transportation.
      </>
    ),
    fr: (
      <>
        Cher client, chez Gorsele, nous visons un service 5 étoiles. <br />
        Afin d'améliorer continuellement notre service, nous aimerions vous
        demander ce que vous pensez de votre transporte.
      </>
    ),
  },
  better: {
    nl: 'Wat kunnen we volgende keer beter doen?',
    en: 'What can we do better next time?',
    fr: 'Que pouvons-nous faire de mieux la prochaine fois ?',
  },
};

const App = () => {
  const [score, setScore] = useState(undefined);
  const [language, setLanguage] = useState(undefined);
  const [rid, setRid] = useState(undefined);
  const [gsm, setGSM] = useState(undefined);
  const [ridData, setRidData] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [remark, setRemark] = useState(undefined);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const stars = new Array(5).fill('star');

  const sendScore = async () => {
    setLoading(true);
    const url = `https://gorsele-oneoffice-back.herokuapp.com/api/anonymous/reviews/create`;
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        rid,
        review: {
          gsm,
          score,
          remark,
        },
      }),
    });
    setLoading(false);
    const data = await res.json();
    if (!data || !data.success) {
      if (data && data.error) {
        return Swal.fire('Probleem', data.error, 'error');
      }
      return Swal.fire(
        'Probleem',
        'Er is een probleem opgetreden tijdens het opslaan van de gegevens.',
        'error'
      );
    }
    navigate(`/thanks?lang=${language}&score=${score}`);
  };

  const loadReviewData = async (rid, gsm) => {
    setLoading(true);
    const url = `https://gorsele-oneoffice-back.herokuapp.com/api/anonymous/reviews/rid/${rid}/${gsm}`;
    const res = await fetch(url);
    setLoading(false);
    const data = await res.json();
    if (!data || !data.success || !data.order) {
      if (data && data.error) {
        return Swal.fire('Probleem', data.error, 'error');
      }
      return Swal.fire(
        'Probleem',
        'U kan geen review plaatsen voor deze gegevens.',
        'error'
      );
    }
    setRidData({ order: data.order, rid, gsm });
  };

  useEffect(() => {
    const pRid = searchParams.get('rid');
    const pGsm = searchParams.get('gsm');
    if (pRid && pGsm) {
      setRid(pRid);
      setGSM(pGsm);
      loadReviewData(pRid, pGsm);
    }
  }, [searchParams]);

  return (
    <div className={styles.app}>
      <ScaleLoader loading={loading} color="#000000" />
      {!ridData && (
        <p>Er konden geen gegevens ingeladen worden voor deze review</p>
      )}
      {ridData && (
        <div>
          {!language ? (
            <div>
              <div className={styles.lang}>
                <h1>Gorselé Review</h1>
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={() => setLanguage('nl')}
                >
                  Nederlands
                </Button>
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={() => setLanguage('en')}
                >
                  English
                </Button>
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={() => setLanguage('fr')}
                >
                  Français
                </Button>
              </div>
            </div>
          ) : (
            <section id="main">
              <h1>{textData.title[language]}</h1>
              <p>{textData.intro[language]}</p>

              <div className={styles.stars}>
                {stars.map((star, s) => (
                  <span
                    key={s}
                    alt={star}
                    onClick={() => setScore(s + 1)}
                    className={`${score && score > s ? styles.active : ''}`}
                  >
                    <Star />
                  </span>
                ))}
              </div>
              {score < 5 && (
                <div className={styles.remark}>
                  <p>{textData.better[language]}</p>
                  <textarea
                    id="remarks"
                    value={remark || ''}
                    onChange={(e) => setRemark(e.currentTarget.value)}
                    rows={6}
                  />
                </div>
              )}
              {score === 5 && <div></div>}
              {score && (
                <div style={{ marginTop: 30 }}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => sendScore()}
                  >
                    Bevestig review
                  </Button>
                </div>
              )}
            </section>
          )}
        </div>
      )}
    </div>
  );
};

export default App;
