import { useEffect, useState } from 'react';
import styles from './App.module.scss';
import { useSearchParams, useNavigate } from 'react-router-dom';

const textData = {
  thanks: {
    nl: 'Bedankt voor uw review',
    en: 'Thanks for your review',
    fr: 'Merci pour votre avis.',
  },
  google: {
    nl: (
      <>
        Het zou fantastisch zijn moch u ons ook op <b>Google 5 sterren</b>{' '}
        geven, u maakt zo ook kans <strong>op een waardebon van 25 euro</strong>
        ! Gewoon als dank om onze service te beoordelen.
      </>
    ),
    en: (
      <>
        It would be great if you could give us <b>5 stars on Google</b> too, you
        You also have a chance to <strong>win a voucher of 25 euros</strong>!
        Just as a thank you for our assess service.
      </>
    ),
    fr: (
      <>
        Ce serait formidable si vous pouviez nous donner aussi{' '}
        <b>5 étoiles sur Google</b>, vous Vous avez également la chance de
        gagner <strong>un bon d'achat de 25 euros</strong> ! Juste en guise de
        remerciement pour notre évaluer le service.
      </>
    ),
  },
};

const Thanks = () => {
  const [language, setLanguage] = useState(undefined);
  const [score, setScore] = useState(undefined);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const pLang = searchParams.get('lang');
    const pScore = searchParams.get('score');
    if (pLang && pScore) {
      setLanguage(pLang);
      setScore(pScore);
    }
  }, [searchParams]);

  return language && score ? (
    <div className={styles.thanks}>
      <h2>{textData.thanks[language]}</h2>
      {parseInt(score) === 5 && (
        <div className={styles.google}>
          <p>{textData.google[language]}</p>
          <a href="https://g.page/r/CTlZtpxrlHQ8EBE/review">
            <img
              src={`/assets/google_${language}.png`}
              width="140"
              height="140"
              alt="google review"
            />
          </a>
        </div>
      )}
    </div>
  ) : null;
};

export default Thanks;
